import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import withIntegrationCardContext from 'hoc/withIntegrationCardContext';
import Slider from 'react-slick';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

const Zendesk = ({ sliderSettings }) => {
  const { zendesk1, zendesk2, zendesk3 } = useStaticQuery(graphql`
    query {
      zendesk1: file(relativePath: { eq: "marketplace/zendesk/1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      zendesk2: file(relativePath: { eq: "marketplace/zendesk/2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      zendesk3: file(relativePath: { eq: "marketplace/zendesk/3.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const photos = [
    {
      img: zendesk1.childImageSharp.fluid,
      alt: 'Zendesk integration',
      title: 'Integrate LiveSession with Zendesk',
    },
    {
      img: zendesk2.childImageSharp.fluid,
      alt: 'Zendesk integration',
      title: 'Integrate LiveSession with Zendesk',
    },
    {
      img: zendesk3.childImageSharp.fluid,
      alt: 'Zendesk integration',
      title: 'Integrate LiveSession with Zendesk',
    },
  ];

  return (
    <>
      <Slider {...sliderSettings(photos)}>
        {photos.map(({ img, alt, title }) => (
          <div key={img}>
            <Img fluid={img} alt={alt} title={title} />
          </div>
        ))}{' '}
      </Slider>
      <div className="section">
        <h2>Overview</h2>
        <p>
          Zendesk offers a set of tools that help teams providing successful customer support.
          Companies also use it to guide people through the sales process. Using Zendesk for
          service, you can send proactive messages to your customers (like people who spend too much
          time on checkout). Zendesk has a ticketing platform, which handles email, mobile, voice
          and social media tickets. Zendesk also provides a CRM system for sales teams.
          <br />
        </p>
        <p>
          LiveSession Zendesk integration lets you see the most recent session recordings from the
          issuer who submitted a ticket.
        </p>
      </div>
      <div className="section">
        <h2>Benefits</h2>
        <ul className="checkmarks">
          <li>Access session recording straight from Zendesk</li>
          <li>Identify actions that caused technical issues</li>
          <li>Deliver support faster</li>
        </ul>
      </div>
      <h2>How to install</h2>
      <p>
        See a detailed tutorial in our <a href="/help/zendesk-integration/">help center</a>.
      </p>
    </>
  );
};

Zendesk.propTypes = {
  sliderSettings: PropTypes.func.isRequired,
};

export default withIntegrationCardContext(Zendesk);
